export const ReportsIcon = ({
  size = 32,
  width = 32,
  height = 32,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
    fill="inherit"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3704 6.59091V1.5H8.11111C6.3929 1.5 5 3.20946 5 5.31818V25.6818C5 27.7906 6.3929 29.5 8.11111 29.5H20.5556C22.2738 29.5 23.6667 27.7906 23.6667 25.6818V11.6818H19.5185C17.2276 11.6818 15.3704 9.40254 15.3704 6.59091ZM17.4444 6.59091V2.24555L23.0592 9.13636H19.5185C18.373 9.13636 17.4444 7.99673 17.4444 6.59091ZM9.14815 9.13636H13.2963V6.59091H9.14815V9.13636ZM14.3333 12.9545H9.14815V10.4091H14.3333V12.9545ZM13.1159 16.0913C13.9214 15.1275 15.2062 15.1275 16.0117 16.0913L17.2791 17.608C17.6795 18.0871 17.9053 18.7443 17.9053 19.4306V21.8636C17.9053 23.2695 16.9767 24.4091 15.8312 24.4091H13.2963C12.1508 24.4091 11.2222 23.2695 11.2222 21.8636V19.4306C11.2222 18.7443 11.448 18.0871 11.8484 17.608L13.1159 16.0913Z"
    />
  </svg>
);
