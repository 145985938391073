export const DriversIcon = ({
  size = 32,
  width = 32,
  height = 32,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path d="M27.118 21.0366L27.118 21.0367C25.1324 24.8638 22.0397 27.1888 17.7737 27.8292C12.4903 28.6223 7.35067 25.8995 4.96957 21.1469L4.96955 21.1468C4.80226 20.8131 4.72415 20.5 4.73868 20.2054C4.75309 19.9133 4.85925 19.6246 5.08534 19.3377C5.31956 19.0408 5.58488 18.8619 5.87431 18.777C6.16544 18.6916 6.49611 18.6969 6.86584 18.794C7.58296 18.9827 8.30057 19.1685 9.03459 19.3586C9.48409 19.475 9.93973 19.5929 10.4052 19.7141L10.7639 19.8075L10.645 19.4565L8.43934 12.9398L8.38406 12.7765L8.21439 12.8071C8.04202 12.8382 7.87082 12.8688 7.7005 12.8992C7.26564 12.977 6.83656 13.0537 6.40858 13.1348L6.40851 13.1348C5.76445 13.257 5.23409 13.0871 4.82535 12.595C4.4122 12.0976 4.32296 11.5364 4.58081 10.9492L4.58085 10.9491C6.38123 6.84426 9.44871 4.27986 13.8568 3.4645L13.8568 3.4645C17.6585 2.76103 21.0758 3.74558 24.0479 6.22048C25.5883 7.50312 26.7223 9.10898 27.4719 10.9779C27.7082 11.5676 27.617 12.115 27.2124 12.6009C26.8113 13.0827 26.2865 13.2559 25.6551 13.1406L25.6549 13.1406C25.1846 13.0552 24.7147 12.9688 24.227 12.879C24.0961 12.8549 23.9638 12.8306 23.8299 12.806L23.6598 12.7747L23.6043 12.9386C23.3402 13.7192 23.0759 14.499 22.811 15.2809C22.3439 16.6597 21.8745 18.045 21.3992 19.453L21.2813 19.8021L21.6383 19.7107C21.8939 19.6452 22.1406 19.5822 22.3819 19.5206C22.7456 19.4277 23.097 19.3379 23.4481 19.2476L23.4481 19.2476C23.6468 19.1964 23.8446 19.1447 24.042 19.093C24.4608 18.9835 24.8778 18.8743 25.2981 18.7725C25.6445 18.6887 25.9526 18.6991 26.2238 18.7922C26.4947 18.8852 26.7423 19.0654 26.9605 19.3428C27.1707 19.6108 27.2839 19.8862 27.3099 20.1633C27.3358 20.4404 27.2758 20.7327 27.118 21.0366ZM16.053 0.391803C7.64191 0.384405 0.810495 7.17852 0.800012 15.5641C0.789531 23.9899 7.58552 30.8238 15.9864 30.8318C24.4093 30.8404 31.2369 24.0362 31.24 15.6295C31.2431 7.22369 24.4427 0.398586 16.053 0.391803Z" />
    <path d="M21.8935 16.2146C21.4068 17.3993 20.7539 18.4945 20.0164 19.5411C19.6846 20.0218 19.18 20.3557 18.6078 20.4732C17.0711 20.8258 15.4745 20.8258 13.9378 20.4732C13.3854 20.3479 12.9156 20.0923 12.5822 19.6214C11.4644 18.0394 10.4982 16.3819 10.0984 14.4598C10.0125 14.0024 9.98267 13.5364 10.0096 13.0718C10.0351 12.4463 10.3412 12.1415 10.9509 12.0289C12.7106 11.7028 14.4891 11.603 16.3887 11.5957C18.0401 11.6073 19.7962 11.6967 21.5388 12.0216C22.2154 12.1482 22.4892 12.4536 22.5373 13.1485C22.6115 14.2322 22.2951 15.2362 21.8935 16.2146Z" />
  </svg>
);
