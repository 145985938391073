export const UsersIcon = ({
  size = 45,
  width = 45,
  height = 45,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path d="M22.0529 8.77645C22.0529 12.7951 18.7951 16.0529 14.7765 16.0529C10.7578 16.0529 7.5 12.7951 7.5 8.77645C7.5 4.75778 10.7578 1.5 14.7765 1.5C18.7951 1.5 22.0529 4.75778 22.0529 8.77645Z" />
    <path d="M28.9948 27.8414L28.9945 27.8399C28.3796 25.0052 26.8131 22.4663 24.5554 20.6451C22.2978 18.824 19.485 17.8303 16.5844 17.8291C16.5844 17.8291 16.5843 17.8291 16.5842 17.8291L12.9636 17.8291C10.0655 17.8405 7.25753 18.838 5.00177 20.6576C2.74581 22.4774 1.17658 25.011 0.552312 27.8414H28.9948ZM28.9948 27.8414C29.0669 28.169 29.0644 28.5085 28.9876 28.835C28.9107 29.1615 28.7614 29.4665 28.5508 29.7275L28.5502 29.7282C28.3454 29.9828 28.0859 30.188 27.791 30.3286C27.4962 30.469 27.1735 30.5413 26.847 30.5401C26.6026 30.5383 26.361 30.4877 26.1364 30.3915C25.9114 30.2951 25.708 30.1548 25.538 29.9787L25.5381 29.9787L25.5334 29.974C24.3614 28.792 22.9661 27.8547 21.4288 27.2164C19.8916 26.5782 18.243 26.2518 16.5787 26.256M28.9948 27.8414L0.996333 29.7275M0.996333 29.7275L0.996907 29.7282C1.20167 29.9828 1.46117 30.188 1.75613 30.3286C2.05109 30.4691 2.37394 30.5414 2.70067 30.5401L2.70489 30.5401C3.17744 30.5421 3.63151 30.3569 3.96779 30.0251C5.13907 28.8299 6.53738 27.8808 8.08059 27.2335C9.62487 26.5859 11.2831 26.2535 12.9577 26.256H12.9575V26.756L12.9583 26.256L16.5787 26.256M0.996333 29.7275C0.785672 29.4665 0.636389 29.1615 0.55953 28.835C0.482686 28.5086 0.480206 28.1691 0.552271 27.8416L0.996333 29.7275ZM16.5787 26.256H16.5793V26.756L16.578 26.256C16.5782 26.256 16.5785 26.256 16.5787 26.256Z" />
  </svg>
);
