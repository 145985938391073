export const VehiclesIcon = ({
  size = 32,
  width = 32,
  height = 32,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    fill="inherit"
    {...props}
  >
    <path d="M24.7062 1H6.56965C5.15634 1 4 2.23615 4 3.747V24.9494C4 26.3002 4.92514 27.4286 6.13101 27.6531V28.6715C6.13101 29.9522 7.11119 31 8.30919 31C9.50719 31 10.4874 29.9522 10.4874 28.6715V27.6964H20.7885V28.6715C20.7885 29.9522 21.7687 31 22.9667 31C24.1647 31 25.1449 29.9522 25.1449 28.6715V27.6531C26.3507 27.4286 27.2759 26.3002 27.2759 24.9494V3.747C27.2759 2.23615 26.1195 1 24.7062 1ZM8.30919 24.3638C7.46711 24.3638 6.78446 23.634 6.78446 22.7338C6.78446 21.8336 7.46711 21.1039 8.30919 21.1039C9.15127 21.1039 9.83391 21.8336 9.83391 22.7338C9.83391 23.634 9.15127 24.3638 8.30919 24.3638ZM22.9667 24.3638C22.1246 24.3638 21.442 23.634 21.442 22.7338C21.442 21.8336 22.1246 21.1039 22.9667 21.1039C23.8088 21.1039 24.4914 21.8336 24.4914 22.7338C24.4914 23.634 23.8088 24.3638 22.9667 24.3638ZM25.1449 17.9022H6.13101V9.91251C6.13101 9.52011 6.4286 9.20198 6.79567 9.20198H24.4802C24.8473 9.20198 25.1449 9.52011 25.1449 9.91251V17.9022ZM25.5214 5.1405C25.5214 6.15624 24.744 6.98733 23.7938 6.98733H7.89392C6.94186 6.98733 6.1629 6.1546 6.1629 5.13683V5.08311C6.1629 4.06535 6.94186 3.23262 7.89392 3.23262H23.7938C24.744 3.23262 25.5214 4.06368 25.5214 5.07945V5.1405Z" />
    <path d="M24.7062 1H6.56965C5.15634 1 4 2.23615 4 3.747V24.9494C4 26.3002 4.92514 27.4286 6.13101 27.6531V28.6715C6.13101 29.9522 7.11119 31 8.30919 31C9.50719 31 10.4874 29.9522 10.4874 28.6715V27.6964H20.7885V28.6715C20.7885 29.9522 21.7687 31 22.9667 31C24.1647 31 25.1449 29.9522 25.1449 28.6715V27.6531C26.3507 27.4286 27.2759 26.3002 27.2759 24.9494V3.747C27.2759 2.23615 26.1195 1 24.7062 1ZM8.30919 24.3638C7.46711 24.3638 6.78446 23.634 6.78446 22.7338C6.78446 21.8336 7.46711 21.1039 8.30919 21.1039C9.15127 21.1039 9.83391 21.8336 9.83391 22.7338C9.83391 23.634 9.15127 24.3638 8.30919 24.3638ZM22.9667 24.3638C22.1246 24.3638 21.442 23.634 21.442 22.7338C21.442 21.8336 22.1246 21.1039 22.9667 21.1039C23.8088 21.1039 24.4914 21.8336 24.4914 22.7338C24.4914 23.634 23.8088 24.3638 22.9667 24.3638ZM25.1449 17.9022H6.13101V9.91251C6.13101 9.52011 6.4286 9.20198 6.79567 9.20198H24.4802C24.8473 9.20198 25.1449 9.52011 25.1449 9.91251V17.9022ZM25.5214 5.1405C25.5214 6.15624 24.744 6.98733 23.7938 6.98733H7.89392C6.94186 6.98733 6.1629 6.1546 6.1629 5.13683V5.08311C6.1629 4.06535 6.94186 3.23262 7.89392 3.23262H23.7938C24.744 3.23262 25.5214 4.06368 25.5214 5.07945V5.1405Z" />
  </svg>
);
