export const AnnouncementIcon = ({
  size = 32,
  width = 32,
  height = 32,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
    fill="inherit"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4642 20.0905V25.2833C7.4642 26.7543 8.86486 28 10.1428 28C11.4218 28 12.8214 26.807 12.8214 25.335V20.0907L7.4642 20.0905ZM14.6071 7.92198V19.9553L21.5514 23.7133C21.9666 23.9385 22.4711 23.9308 22.8773 23.6935C23.2858 23.4562 23.5358 23.0245 23.5358 22.5587V5.31826C23.5358 4.8525 23.2858 4.42077 22.8773 4.18347C22.471 3.94619 21.9666 3.9385 21.5514 4.1637L14.6071 7.92198ZM13.7142 8.22626H7.4642C7.4508 8.22626 7.43741 8.22736 7.42402 8.22846C4.56909 8.48112 3 11.0683 3 13.9387C3 16.8092 4.56921 19.3961 7.42402 19.649C7.43741 19.6501 7.45081 19.6511 7.4642 19.6511H13.7142V8.22626ZM23.9823 9.88945V18.1372C26.2792 17.7769 28 16.05 28 14.0133C28 11.9767 26.279 10.2497 23.9823 9.88945Z"
    />
  </svg>
);
