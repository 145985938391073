export const EyeIcon = ({
  size = 17,
  width = 17,
  height = 17,
  ...props
}: {
  size?: number;
  width?: any;
  height?: any;
}) => (
  <svg
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="grey"
    {...props}
  >
    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
    <path
      fillRule="evenodd"
      d="M1.38 8.28a.87.87 0 0 1 0-.566 7.003 7.003 0 0 1 13.238.006.87.87 0 0 1 0 .566A7.003 7.003 0 0 1 1.379 8.28ZM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
    />
  </svg>
);
